<template>
    <div class="ContactUs_div row justify-content-center " style="padding-top: 100px;" id="ContactUs">
        <h2 class="background double col-12"><span>Contact Us</span></h2>
        <div class="ContactUs_context row justify-content-center">
            <div class=" ContactUs_context_left col-lg-4 col-sm-10">
                <div class=" ContactUs_info">
                    <div class=" ContactUs_info_context" v-for="(item, index) in leftData" :key="index">
                        <i :class="item.icon"></i><span class="ContactUs_info_context-detail">{{ item.context }}</span>


                    </div>

                </div>
                <div class="share_icon">
                    <a href="JavaScript::void(0);" class="icon-button twitter"><i
                            class="iconfont icon-ins"></i><span></span></a>
                    <a href="JavaScript::void(0);" class="icon-button facebook"><i
                            class="iconfont  icon-icon_facebook"></i><span></span></a>
                    <a href="JavaScript::void(0);" class="icon-button google-plus"><i
                            class="iconfont icon-googleplus"></i><span></span></a>
                </div>
            </div>
            <div class="ContactUs_context_right col-lg-4 col-sm-10">
                <input type="text" class="form-control" placeholder="Your Name">
                <input type="text" class="form-control" placeholder="Your Email">
                <input type="text" class="form-control" placeholder="Subject">
                <textarea class="form-control" placeholder="Message" id="floatingTextarea2"
                    style="height: 100px"></textarea>
                <button type="button" class="btn btn-primary ">Send Message</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'WorkspaceJsonContactUs',

    data() {
        return {
            leftData: [
                {
                    icon: 'iconfont icon-home',
                    context: 'CLIXYES TECH(OPC) PRIVATE LIMITED',
                }, {
                    icon: 'iconfont icon-dingwei',
                    context: 'New Delhi,Nangloi Extension, PLOT NO.81, KH NO 41/11, DESK NO 2',
                }, {
                    icon: 'iconfont icon-send-sms',
                    context: 'Callmeazu@gmail.com',
                }, {
                    icon: 'iconfont icon-dianhua',
                    context: '+91 9891536296',
                }
            ]
        };
    },

    beforeCreate() {

    },

    methods: {

    },
};
</script>
<style lang="less" scoped>
@import "../assets/css/iconfont.css";

.ContactUs_context {
    padding: 64px 0 0px 0;

    .ContactUs_context_left {
        .ContactUs_info {
            .ContactUs_info_context {
                display: flex;
                align-items: center;
                margin-bottom: 16px;

                .iconfont {
                    margin-right: 8px;
                    font-size: 24px;
                    color: #3498db;
                }

                .ContactUs_info_context-detail {
                    line-height: 28px;
                    font-size: 14px;
                    color: #444444;
                }
            }
        }

        .share_icon {}
    }

    .ContactUs_context_right {
        .form-control {
            margin-bottom: 16px;
        }

        .control::placeholder {
            color: #f4f4f4b6 !important;
        }
    }
}

h2.background {

    position: relative;
    z-index: 1;
    text-align: center;

    &:before {
        border-top: 2px solid #dfdfdf;
        content: "";
        margin: 0 auto;
        /* this centers the line to the full width specified */
        position: absolute;
        /* positioning must be absolute here, and relative positioning must be applied to the parent */
        top: 50%;
        left: 0;
        right: 0;
        bottom: 0;
        width: 95%;
        z-index: -1;
    }

    span {
        /* to hide the lines from behind the text, you have to set the background color the same as the container */
        background: #fff;
        padding: 0 15px;
        font-family: "MarcellusSC", sans-serif;
    }
}

h2.double:before {
    /* this is just to undo the :before styling from above */
    border-top: none;
}

h2.double:after {
    border-bottom: 3px solid #3498db;
    // -webkit-box-shadow: 0 1px 0 0 red;
    // -moz-box-shadow: 0 1px 0 0 red;
    // box-shadow: 0 1px 0 0 red;
    content: "";
    margin: 0 auto;
    /* this centers the line to the full width specified */
    position: absolute;
    top: 45%;
    left: 0;
    right: 0;
    width: 30%;
    z-index: -1;
}

.share_icon {
    .iconfont {
        font-size: 24px;
        color: #3498db !important;
    }

    /* Wrapper */
    .icon-button {
        // background-color: #f4f4f4;
        border: 2px solid #3498db;

        border-radius: 3.6rem;
        cursor: pointer;
        display: inline-block;
        font-size: 2.0rem;
        height: 3.6rem;
        line-height: 3.6rem;
        margin: 0 5px;
        position: relative;
        text-align: center;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        width: 3.6rem;
    }

    /* Circle */
    .icon-button span {
        border-radius: 0;
        display: block;
        height: 0;
        left: 50%;
        margin: 0;
        position: absolute;
        top: 50%;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
        width: 0;
    }

    .icon-button:hover span {
        width: 3.6rem;
        height: 3.6rem;
        border-radius: 3.6rem;
        margin: -1.8rem;
    }

    .icon-button:hover .iconfont {
        color: #fff !important;
    }

    .twitter span {
        background-color: #4099ff;
    }

    .facebook span {
        background-color: #3B5998;
    }

    .google-plus span {
        background-color: #db5a3c;
    }

    /* Icons */
    .icon-button i {


        background: none;
        color: white;
        height: 3.6rem;
        left: 0;
        line-height: 3.6rem;
        position: absolute;
        top: 0;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
        width: 3.6rem;
        z-index: 10;
    }

    .icon-button .icon-twitter {
        color: #4099ff;
    }

    .icon-button .icon-facebook {
        color: #3B5998;
    }

    .icon-button .icon-google-plus {
        color: #db5a3c;
    }

    .icon-button:hover .icon-twitter,
    .icon-button:hover .icon-facebook,
    .icon-button:hover .icon-google-plus {
        color: white;
    }
}
</style>
