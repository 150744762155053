var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{attrs:{"id":"cart_calc_modal","centered":""},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_vm._v(" Modify the shipping address "),_c('button',{on:{"click":_vm.myClose}},[_c('i',{staticClass:"fa fa-times",attrs:{"aria-hidden":"true"}})])]},proxy:true},{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"mx-auto footer_bts"},[_c('button',{staticClass:"myorange",on:{"click":_vm.submit_modify}},[_vm._v(" Confirm ")]),_c('button',{staticClass:"mygray",on:{"click":_vm.myClose}},[_vm._v(" Cancel ")])])]},proxy:true}])},[_c('div',{staticClass:"item",class:{
        'input-active': _vm.tmp_realname && _vm.tmp_realname.length || _vm.focusing1,
        'input-focus': _vm.focusing1
    }},[_c('label',{attrs:{"for":"realname"}},[_vm._v("Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tmp_realname),expression:"tmp_realname"}],attrs:{"type":"text","name":"realname","id":""},domProps:{"value":(_vm.tmp_realname)},on:{"focusin":function($event){_vm.focusing1 = true;},"focusout":function($event){_vm.focusing1 = false;},"input":function($event){if($event.target.composing)return;_vm.tmp_realname=$event.target.value}}})]),_c('div',{staticClass:"item",class:{
        'input-active': _vm.tmp_phonenum && _vm.tmp_phonenum.length || _vm.focusing2,
        'input-focus': _vm.focusing2
    }},[_c('label',{attrs:{"for":"phone"}},[_vm._v("Contact number")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tmp_phonenum),expression:"tmp_phonenum"}],attrs:{"type":"text","name":"phone","id":""},domProps:{"value":(_vm.tmp_phonenum)},on:{"focusin":function($event){_vm.focusing2 = true;},"focusout":function($event){_vm.focusing2 = false;},"input":function($event){if($event.target.composing)return;_vm.tmp_phonenum=$event.target.value}}})]),_c('div',{staticClass:"item",class:{
        'input-active': _vm.tmp_addr && _vm.tmp_addr.length || _vm.focusing3,
        'input-focus': _vm.focusing3
    }},[_c('label',{attrs:{"for":"addr"}},[_vm._v("Delivery address")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tmp_addr),expression:"tmp_addr"}],attrs:{"type":"text","name":"addr","id":""},domProps:{"value":(_vm.tmp_addr)},on:{"focusin":function($event){_vm.focusing3 = true;},"focusout":function($event){_vm.focusing3 = false;},"input":function($event){if($event.target.composing)return;_vm.tmp_addr=$event.target.value}}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }