<template>
    <div class="cart_more_shop">
        <h2 class="rec_title">
            <span>The person who bought the items in the cart also bought them</span>
        </h2>
        <div class="row mycards">
            <div class="mycards_options" v-for="(item, index) of rlist">
                <div class="card">
                    <router-link :to="{
                        name: 'product',
                        query: {
                            pid: item.pid
                        }
                    }" class="card-img-top w-75 mx-auto mt-3">
                        <img :src="'' + item.img_path" alt="Product picture" class="mx-auto d-block w-100">
                    </router-link>
                    <div class="card-body">
                        <h5 class="card-title">{{ item.pname }}</h5>
                        <p class="card-text">${{ item.min_price }} </p>
                    </div>
                    <button class="gocheck" @click="$router.push({
                        name: 'product',
                        query: {
                            pid: item.pid
                        }
                    })">Add to cart</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
export default {
    name: 'recommendation',
    computed: {
        ...mapState(['location_prefix']),
    },
    data() {
        return {
            rlist: null,
            showing: -1
        }
    },
    mounted() {
        fetch(this.location_prefix + '/recommend')
            .then(res => res.json())
            .then(res => {
                this.rlist = res
                console.log(this.rlist)
            })
    }
}
</script>
<style lang="less" scoped>
.mycards {

    margin: 60px 16px;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-row-gap: 8px;

    .mycards_options {
        // display: flex;
        // flex-direction: column;
        // align-items: center;
        text-align: center;

        .card-body {
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }
    }

    .card {
        position: relative;
        // height: 300px;
        margin-bottom: 15px;
        border-radius: 0px;
        border: none;
        overflow: hidden;

        img {
            width: auto;
            height: 134px;
            object-fit: contain;
        }

        @media (max-width: 768px) {
            height: 200px;

            * {
                font-size: 8px !important;
            }

            img {
                width: 50% !important;
            }
        }

        .gocheck {
            position: absolute;
            transition: all .3s ease;
            bottom: -28px;
            left: 50%;
            transform: translateX(-50%);
            font-size: 12px;
            width: 120px;
            height: 28px;
            border: solid 1px #ff6700;
            color: #ff6700;
            background-color: white;

            &:hover {
                color: white;
                border-color: #f25807;
                background-color: #f25807;
            }
        }

        &:hover {
            .gocheck {

                bottom: 20px;
            }


        }



        .card-title {
            text-align: center;
            white-space: nowrap;
            /* 不换行 */
            overflow: hidden;
            /* 隐藏超出的内容 */
            text-overflow: ellipsis;
            /* 使用省略号表示被截断的部分 */
            width: 140px;
            /* 设定宽度 */
            font-size: 14px;
            height: 18px;
            text-align: center;
            margin-bottom: 10px;
            color: #333;
        }

        .card-text {
            text-align: center;
            font-size: 14px;
            color: #ff6700;
        }
    }
}

.cart_more_shop {
    width: 100%;
}

.rec_title {
    margin-top: 80px;
    border-top: solid 1px rgb(224, 224, 224);
    position: relative;

    span {
        position: absolute;
        left: 50%;
        top: 0;
        display: block;
        padding: 0 40px;
        white-space: nowrap;
        transform: translate(-50%, -50%);
        background-color: rgb(245, 245, 245);
        color: rgb(117, 117, 117);
        font-size: 30px;
    }
}
</style>