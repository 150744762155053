<template>
    <div>
        <h1>Stay tuned!</h1>
    </div>
</template>
<script>

export default {
    name: 'resign_center',

}
</script>
<style lang="less" scoped></style>