var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"collection_center center"},[_c('h1',[_vm._v("Collection Center")]),_c('div',{staticClass:"collection_panel"},[_c('ul',_vm._l((_vm.clist),function(item){return _c('li',{key:item.collection_id},[_c('router-link',{attrs:{"to":{
                    name: 'product',
                    query: {
                        pid: item.pid
                    }
                }}},[_c('img',{attrs:{"src":_vm.location_prefix + item.img_path,"alt":""}})]),_c('router-link',{attrs:{"to":{
                    name: 'product',
                    query: {
                        pid: item.pid
                    }
                }}},[_c('p',{staticClass:"cname"},[_vm._v(_vm._s(item.pname))])]),_c('p',{staticClass:"price"},[_vm._v("$"+_vm._s(item.min_price)+" ")]),_c('div',{staticClass:"bt_area"},[_c('button',{staticClass:"detail_bt",on:{"click":function($event){return _vm.$router.push({
                        name: 'product',
                        query: {
                            pid: item.pid
                        }
                    })}}},[_vm._v("Details")]),_c('button',{staticClass:"del_bt",on:{"click":function($event){return _vm.rm(item.pid)}}},[_vm._v("Delete")])])],1)}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }