var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel",attrs:{"id":_vm.gitem_list.id}},[_c('div',{staticClass:"p_header"},[_c('p',[_vm._v(_vm._s(_vm.gitem_list.left_title))]),_c('div',{staticClass:"d-inline-block float-end"},[_c('div',{staticClass:"right_tabs"},_vm._l((_vm.gitem_list.right_tabs),function(r_tab,index){return _c('a',{class:{ 'a_selected': _vm.cur_disp === index && r_tab.type === 1 },attrs:{"href":"javascript:void(0);"},on:{"mouseover":function($event){_vm.cur_disp = index}}},[_vm._v(" "+_vm._s(r_tab.content)+" "),(r_tab.type === 0)?_c('i',{staticClass:"fa fa-angle-right"}):_vm._e()])}),0)])]),_vm._l((_vm.gitem_list.arr),function(gitem_list_group,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.cur_disp === index),expression:"cur_disp === index"}],staticClass:"grid_container"},[_c('router-link',{staticClass:"gitem left_banner",class:{ 'half_left_banner': _vm.gitem_list.banner.length === 2 },attrs:{"href":"","to":{
                name: 'product',
                query: {
                    pid: _vm.gitem_list.banner[0].id,
                    pname: _vm.gitem_list.banner[0].name
                }
            }}},[_c('img',{staticClass:"left_img",attrs:{"src":_vm.gitem_list.banner[0].img_path}})]),(_vm.gitem_list.banner.length === 2)?_c('router-link',{staticClass:"gitem left_banner half_left_banner",staticStyle:{"grid-row":"2/span 1"},attrs:{"href":"","to":{
            name: 'product',
            query: {
                pid: _vm.gitem_list.banner[1].id,
                pname: _vm.gitem_list.banner[1].name
            }
        }}},[_c('img',{attrs:{"src":_vm.gitem_list.banner[1].img_path}})]):_vm._e(),_vm._l((gitem_list_group),function(gitem_list_item,index){return [(!gitem_list_item.halfs)?_c('router-link',{staticClass:"gitem",attrs:{"to":{
                name: 'product',
                query: {
                    pid: gitem_list_item.id,
                    pname: gitem_list_item.name
                }
            }}},[_c('div',{staticClass:"gitem_card"},[_c('img',{attrs:{"src":gitem_list_item.img_path}}),_c('h6',{staticClass:"oneWord"},[_vm._v(_vm._s(gitem_list_item.name))]),_c('p',{staticClass:"gitem_card_context",domProps:{"innerHTML":_vm._s(gitem_list_item.discription)}}),_c('div',{staticClass:"price"},[_c('span',[_vm._v("$"+_vm._s(gitem_list_item.price))]),_vm._v(" "),_c('del',[_vm._v(_vm._s(gitem_list_item.old_price))])])])]):_c('div',{staticClass:"last_icon"},_vm._l((gitem_list_item.halfs),function(h){return _c('router-link',{staticClass:"last_icon_half",attrs:{"to":{
                    name: 'product',
                    query: {
                        pid: h.id,
                        pname: h.name
                    }
                }}},[(h.img_path)?_c('img',{staticStyle:{"margin":"16px","width":"90px"},attrs:{"src":h.img_path}}):_vm._e(),_c('p',[_vm._v(_vm._s(h.name))]),(h.price)?_c('p',{staticClass:"price"},[_vm._v("$"+_vm._s(h.price))]):_vm._e(),(h.more_of_what)?_c('p',{staticClass:"more"},[_vm._v(" Read More"),_c('br'),_c('small',[_vm._v(_vm._s(h.more_of_what)+" "),_c('i',{staticClass:"fa fa-arrow-circle-o-right"})])]):_vm._e()])}),1)]})],2)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }