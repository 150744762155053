<template>
    <div class="about_div  container-fluid ">
        <div class="aboutsToDo row justify-content-center align-items-start  padding-bt">
            <!-- 抬头第一部分 -->

            <div class="aboutsToDo_title col-lg-5 col-sm-12 ">
                <div class="red_small_title">SOME WORDS ABOUT US</div>
                <div class="bigtitle">Well-coordinated teamwork speaks About Us</div>
            </div>
            <div class="aboutsToDo_content  col-lg-2 col-sm-12" v-for="(item, index) in aboutTitle" :key="index">
                <div class="aboutsToDo_content_subtitle">
                    {{ item.title }}
                </div>
                <div class="aboutsToDo_content_detail">
                    {{ item.context }}
                </div>
            </div>



        </div>
        <!-- 工作叙述 -->
        <div class="JobNarrative row justify-content-center padding-bt">
            <div class="JobNarrative_top col-10 row justify-content-center">
                <!-- 图片展示 -->
                <div class="JobNarrative_show col-lg-6 col-sm-12">
                    <img :src="JobNarrativeData.img" alt="">
                </div>
                <div class="JobNarrative_narrate col-lg-6 col-sm-12">
                    <div class="red_small_title" style="font-size: 14px;">{{ CompanyName }}</div>
                    <div class="bigtitle">About our online store</div>
                    <div class="TitleRetelling">{{ JobNarrativeData.Retelling }}</div>
                    <div class="ContentElaboration" v-for="(item, index) in JobNarrativeData.ContentElaboration"
                        :key="index">{{ item }}</div>
                </div>
            </div>
            <div class="JobNarrative_bottom col-10 row justify-content-center">
                <div class="JobNarrative_detail col-lg-12" v-for="(item, index) in JobNarrativeData.detail"
                    :key="index">{{
                        item
                    }}</div>
            </div>
        </div>
        <!-- 联系我们 -->
        <ContactUs></ContactUs>

    </div>
</template>

<script>
import ContactUs from './ContactUs.vue';


export default {
    name: 'AboutUs',
    components: {
        ContactUs
    },
    data() {
        return {
            CompanyName: 'EZ Shop',

            aboutTitle: [
                {
                    title: 'We love what we do',
                    context: "Passion drives us. With every design, we pour our hearts into creating captivating visuals that bring your vision to life."
                },
                {
                    title: 'Our working process',
                    context: "Discover, design, deliver. We collaborate closely with you to understand your vision, craft compelling designs, and deliver exceptional results, every step of the way."
                }
            ],
            // 中间部分内容
            JobNarrativeData: {
                img: 'https://digibizshop.in/wp-content/uploads/2024/03/Banners-5-430x430.png',
                Retelling: "At EZ Shop, we're more than just a design agency – we're your creative partner in bringing visions to life. With a passion for design and a commitment to excellence, we strive to surpass expectations and deliver outstanding results tailored to your unique needs.",
                ContentElaboration: ["Our journey began with a simple yet powerful idea: to harness the power of graphic design to elevate brands and make a lasting impact. From humble beginnings, we've grown into a trusted name in the industry, known for our innovative approach and unwavering dedication to client satisfaction.",
                    "What sets us apart is our personalized approach to every project. We understand that no two businesses are alike, and we take the time to truly understand your goals, values, and target audience. Whether you're a small startup looking to make a big splash or an established brand seeking a fresh perspective, we're here to help you stand out in a crowded marketplace."],
                detail: ["Our team of talented designers brings a wealth of experience and creativity to the table, ensuring that each design is not only visually stunning but also strategically crafted to achieve your objectives. From concept to execution, we collaborate closely with you every step of the way, ensuring that your vision is brought to life with precision and care.",
                    "But our commitment doesn't end with the delivery of the final product. We believe in building long-term partnerships based on trust, transparency, and mutual success. Whether you need a one-time design project or ongoing support, you can count on us to be there for you every step of the way.",
                    "At EZ Shop, we're more than just a design agency – we're your partner in success. Let's embark on this journey together and unlock the full potential of your brand through the power of design."]
            }
        };
    },

    mounted() {

    },

    methods: {

    },
};
</script>
<style lang="less" scoped>
.about_div {
    width: 100%;
    font-family: 'Lora', sans-serif;

    .red_small_title {

        font-weight: 600;
        color: rgb(248, 164, 156);
        line-height: 22.4px;
    }

    .padding-bt {
        padding: 100px 0;
    }

    .bigtitle {
        font-size: 36px;
        color: #242424;
        line-height: 50.4px;
        font-family: 'MarcellusSC', sans-serif;
        text-align: left;
        line-height: 50.4px;

    }

    .aboutsToDo {
        // display: grid;
        // align-items: center;
        // grid-template-columns: repeat(4, 1fr);
        // gap: 1rem;
        // height: max-content;
        padding: 100px 0;
        background-color: #F9F9F9;
        // align-items: start;



        .aboutsToDo_title {
            padding-right: 100px;
        }

        .aboutsToDo_content {
            .aboutsToDo_content_subtitle {
                font-family: 'MarcellusSC', sans-serif;

                line-height: 2;
                font-size: 18px;
            }
        }
    }

    //中间内容
    .JobNarrative {
        background: #f4f4f4;

        .JobNarrative_top {
            margin: 0 0 32px 0;
        }

        .JobNarrative_show {
            text-align: left;

            img {
                width: 76%;
                // height: 512px;
                height: auto;
                object-fit: contain;
            }
        }

        .JobNarrative_narrate {
            padding-top: 36px;

            .TitleRetelling {
                padding: 20px 0;
                color: #000000;
                opacity: 0.3;
                line-height: 25.6px;
            }

            .ContentElaboration {
                padding: 20px 0;
                color: #262626;
            }
        }

        .JobNarrative_bottom {
            .JobNarrative_detail:first-child {
                padding: 0 0 10px 0 !important;
            }

            .JobNarrative_detail {
                padding: 10px 0;
                line-height: 25.6px;
                color: #262626
            }
        }

    }
}
</style>