var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h_body"},[_c('div',{staticClass:"container-xl h_content"},[_c('div',{staticClass:"myrow"},[_c('div',{staticClass:"h_nav"},[_c('ul',[_c('li',[_c('router-link',{class:{ 'here': _vm.$route.name === 'order_center' },attrs:{"to":{
                            name: 'order_center'
                        }}},[_vm._v(" Order Center ")])],1),_c('li',[_c('router-link',{class:{ 'here': _vm.$route.name === 'person_center' },attrs:{"to":{
                            name: 'person_center'
                        }}},[_vm._v(" Personal Center ")])],1),_c('li',[_c('router-link',{class:{ 'here': _vm.$route.name === 'collection_center' },attrs:{"to":{
                            name: 'collection_center'
                        }}},[_vm._v(" Collections Center ")])],1)])]),_c('div',{staticClass:"h_view"},[_c('router-view')],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }