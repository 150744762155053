import { render, staticRenderFns } from "./collection_center.vue?vue&type=template&id=333c34e9&scoped=true"
import script from "./collection_center.vue?vue&type=script&lang=js"
export * from "./collection_center.vue?vue&type=script&lang=js"
import style0 from "./collection_center.vue?vue&type=style&index=0&id=333c34e9&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "333c34e9",
  null
  
)

export default component.exports