<template>
    <div class="list_row item_row">
        <div class="list_col"><i class="iconfont icon-check" @click="$emit('Mycheck', $event, cart_item)"></i></div>
        <div class="list_col img_col"><img :src="'' + cart_item.img_cover" alt=""></div>
        <div class="list_col name_col">
            <router-link :to="{
                name: 'product',
                query: {
                    pid: cart_item.pid
                }
            }">
                {{ namePlusChoices(cart_item) }}

            </router-link>
        </div>
        <div class="list_col single_price_col">${{ cart_item.price | capitalize }}</div>
        <div class="list_col quantity_col">
            <div class="quantity_wrap">
                <button class="lb" @click=" NumChange(0)">-</button>
                <input type="text" name="" id="" v-model.number="tmp">
                <button class="rb" @click="NumChange(1)">+</button>

            </div>

            <!-- {{  }} -->

        </div>
        <div class="list_col price_count">${{ (cart_item.price * cart_item.quantity) | capitalize }}</div>
        <div class="list_col rm_col">
            <a href="javascript:void(0);" @click="$emit('Myremove', cart_item.cart_id)">
                <i class="fa fa-remove"></i>
            </a>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
// import onlynum from '@/assets/js/onlynum'
export default {
    name: 'cart_calc_item_row',
    props: ['cart_item'],
    computed: {
        ...mapState(['location_prefix'])
    },
    data() {
        return {
            tmp: this.cart_item.quantity
        }
    },
    filters: {
        capitalize(value) {
            if (!value) return ''
            return parseFloat(value).toFixed(2)
        }
    },
    // mixins:[onlynum],

    methods: {
        //数量变化
        NumChange(parms) {
            if (parms) {

                this.tmp += 1
                this.$emit("Cart_calc_number_change", this.cart_item, 1)
            } else {
                this.tmp -= 1
                this.$emit("Cart_calc_number_change", this.cart_item, -1)

            }
            this.myonlyNumber(this.tmp)

        },
        namePlusChoices(item) {
            let ret = item.detail.pname
            for (let c of item.choices) {
                ret += ' ' + c.choice_name
            }
            return ret
        },
        myonlyNumber(nxt) {
            const reg = /\D/g
            this.tmp = parseInt(
                nxt.toString().replace(reg, '')
            )
            if (nxt === '' || nxt === 0 || isNaN(nxt)) {
                this.tmp = 1
            }
            this.updQuantity(this.tmp)
        },
        updQuantity(tep) {
            let target = this.location_prefix + `/users/cart/modifyQuantity`
            fetch(target, {
                method: 'POST',
                body: JSON.stringify({
                    quantity: this.cart_item.quantity,
                    cart_id: this.cart_item.cart_id
                }),
                headers: {
                    "Content-Type": "application/json",
                },
            }).then(res => res.json())
                .then(res => {
                    if (res.ok) {
                        this.cart_item.quantity = tep
                        console.log('modify quantity successful!@@')
                    }
                    else {
                        console.log('modify quantity failed!@@')
                    }
                })
        }
    }
}
</script>