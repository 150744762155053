var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"swiper-slide"},[_c('router-link',{attrs:{"href":"javascript:;","to":{
    name: 'product',
    query: {
      'pid': _vm.swiper_slide_item.id,
      'pname': _vm.swiper_slide_item.name
    }
  }}},[_c('img',{attrs:{"src":_vm.swiper_slide_item.path}})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }