var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cct_outer"},[_c('div',{staticClass:"container-xl mx-auto cct"},[_c('div',{staticClass:"logo_div"},[_c('router-link',{attrs:{"to":{

                name: 'front_page'
            }}},[_c('img',{staticClass:"img-fluid d-block",staticStyle:{"transform":"scale(1.2)"},attrs:{"src":require("@/assets/images/logo.jpg"),"alt":""}})])],1),_c('h2',[_vm._v(" "+_vm._s(_vm.route2title.get(_vm.$route.name))+" ")]),(_vm.$route.name === 'part1')?_c('p',[_vm._v("Tips: Whether the product is successfully purchased is subject to the final order, please settle as soon as possible")]):_vm._e(),_c('div',{staticClass:"right"},[_c('router-link',{attrs:{"to":{
                name: 'homepage'
            }}},[_vm._v(_vm._s(this.username))]),_c('span',[_vm._v("|")]),_c('router-link',{attrs:{"to":{
                name: 'order_center'
            }}},[_vm._v("My order")])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }